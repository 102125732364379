import React from 'react'
import { Button, Modal, Form, Item, Grid, Message } from 'semantic-ui-react'
import api from '../../api'
import ReCAPTCHA from "react-google-recaptcha"

import { getRefId } from '../../helpers/cookie'

import PhoneInput from 'react-phone-input-2'



const RequestViewingModal = ({ isOpen, onClose, form, setForm, data }) => {

  const [selectedTime, setSelectedTime] = React.useState(1)
  const [isSuccess, setIsSuccess] = React.useState(0)//ilk hali 0, başarılı 1, başarısız 2
  const date = new Date()
  const dateTomorrow = new Date()
  dateTomorrow.setDate(date.getDate() + 1)
  const [captch, setCaptch] = React.useState(true)
  const [captchValue, setCaptchValue] = React.useState('')

  // const refId = getCookie('refId') 

  const URL = "https://easyrent.s3.eu-central-1.amazonaws.com/Users/"


  const requestViewingPost = async () => {
    setCaptch(true)
    let time = 'Afternoon'

    let model = {
      date: '',
      name: form?.name,
      phone: form?.phoneNumber,
      email: form?.email,
      propertyId: form?.id, time: '',
      captcha: captchValue,
      refId: getRefId() == null ? 0 : getRefId(),
      sourceType:2
    }

    model = selectedTime < 3 ? { ...model, date: date } : { ...model, date: dateTomorrow }
    time = selectedTime % 3 === 0 ? 'Morning' : selectedTime % 3 === 1 ? 'Afternoon' : 'Evening'
    model = { ...model, time: time }
    const result = await api.requestViewing.requestViewing(model)
    if (result.statusCode == 200) {
      setIsSuccess(1)
      window.location.href = "../../ThankYou";
    } else {
      setIsSuccess(2)
    }
    setCaptch(false)
  }

  const renderButton = (text, code) => {
    let basic = true, positive = false
    if (code == selectedTime) {
      basic = false
      positive = true
    }

    return <Button basic={basic} positive={positive} onClick={() => setSelectedTime(code)}>{text}</Button>
  }

  const messagePositive = () => (
    <Message positive>
      <Message.Header>Successful</Message.Header>
      <p>
        Your request has been sent successfully
      </p>
    </Message>
  )

  const messageNegative = () => (
    <Message negative>
      <Message.Header>Error</Message.Header>
      <p>An unexpected error has occurred. Please try again.</p>
    </Message>
  )

  const onChange = (value) => {
    console.log("Captcha value:", value);
    if (value !== null) {
      setCaptchValue(value)
      setCaptch(false)
    } else {
      setCaptch(true)
    }
  }

  return (
    <Modal
      closeIcon
      size={"mini"}
      open={isOpen}
      onClose={() => onClose()}
    >
      <Modal.Header>Request a Viewing</Modal.Header>
      {
        isSuccess === 1 ? messagePositive() : isSuccess === 2 ? messageNegative() : null
      }
      <Modal.Content>
        <div style={{ fontSize: 'x-small' }}>
          <span>TODAY, {new Intl.DateTimeFormat('en-US', { month: 'long' }).format(date.getMonth()).toUpperCase()} {date.getDate()}</span> <br />
          {renderButton('Morning', 0)}
          {renderButton('Afternoon', 1)}
          {renderButton('Evening', 2)}
        </div>
        <br />
        <div style={{ fontSize: 'x-small' }}>
          <span>TOMORROW,  {new Intl.DateTimeFormat('en-US', { month: 'long' }).format(dateTomorrow.getMonth()).toUpperCase()} {dateTomorrow.getDate()}</span> <br />
          {renderButton('Morning', 3)}
          {renderButton('Afternoon', 4)}
          {renderButton('Evening', 5)}
        </div>
        <br />
        <Form>
          <Form.Field>
            <input placeholder='Your Name' value={form?.name} onChange={event => setForm({ ...form, name: event.currentTarget.value })} />
          </Form.Field>
          <Form.Field>

            <PhoneInput
              country={'mt'}
              value={form?.phoneNumber}
              onChange={phone => setForm({ ...form, phoneNumber: phone })}
              inputStyle={{ paddingLeft: '45px' }}
            />

            {/* <input placeholder='Your Phone Number' value={form?.phoneNumber} onChange={event => setForm({ ...form, phoneNumber: event.currentTarget.value })} /> */}
          </Form.Field>
          <Form.Field>
            <input placeholder='Your Email' value={form?.email} onChange={event => setForm({ ...form, email: event.currentTarget.value })} />
          </Form.Field>
          <ReCAPTCHA
            sitekey="6Lf8pMApAAAAAMto6jRherg2r626YulxUoXRseIE"
            onChange={onChange}
          />
          <Button style={{ marginTop: 10 }} onClick={requestViewingPost} disabled={captch} fluid type='button' color="orange">Send Request</Button>
        </Form>

        <Item.Group>
          <Item>
            <Item.Content verticalAlign='middle'>
              <Item.Header>
                <Grid>
                  <Grid.Column width="6">
                    <Item.Image circular size='tiny' src={data?.profilePicture == null ? '/img/avatar.jpg' : URL + data?.profilePicture} />
                  </Grid.Column>
                  <Grid.Column width="10">
                    {data?.firstName + ' ' + data?.lastName} <br />
                    <a href={`tel:${data?.mobilePhone}`}>
                      <span style={{ fontSize: 'medium', color: 'gray' }}>
                        {data?.mobilePhone} <br />
                        {/* {data?.email} */}
                        info@easyrent.com.mt
                      </span>
                    </a>
                  </Grid.Column>
                </Grid>
              </Item.Header>
            </Item.Content>
          </Item>
        </Item.Group>
      </Modal.Content>
    </Modal>
  )
}

export default RequestViewingModal
