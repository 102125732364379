import React from "react";
import { Button, Container, Grid, Image, Card, Icon } from "semantic-ui-react";
import Slider from "react-slick";
import { isMobile } from "react-device-detect";
import { pageEnum } from "../../Common";
import moment from "moment";

import { AwsUrl } from "../../helpers/aws";

function PropertiesSlider({ data }) {
  function getAvailable(date) {
    let duration = moment.duration(moment(date).diff(moment()));
    return duration.asDays() <= 0 ? "Available Now" : (duration.asDays() <= 60 ? "Available Soon":"Available in Few Months");
  }

  function getLettingType(lettingType) {
    switch (lettingType) {
      case pageEnum.Residentials:
        return "Residentials";
      case pageEnum.Commercials:
        return "Commercials";
      default:
        return "";
    }
  }

  const renderItem = (item) => {
    let imgUrl = AwsUrl + item.thumbnailImageUrl;

    return (
      <div className="p-slider-item" key={item.id}>
        <a href={"/detail/" + item.id + "/" + item.lettingType}>
          <Card style={{ width: "100%" }}>
            <Image
              alt={"properties" + item.id}
              src={imgUrl}
              wrapped
              ui={false}
              style={{ height: "200px" }}
            />
            <Card.Content>
              <Card.Header style={{ color: "orange" }}>
                {item.type?.name}
              </Card.Header>

              <Card.Header style={{ marginTop: 5 }}>{item.title}</Card.Header>

              <Card.Meta>
                <Icon name="map pin" color="orange" style={{ marginTop: 15 }} />{" "}
                {item.locationName}
              </Card.Meta>
              <Card.Description>
                Bedrooms: {item.bedrooms} Bathrooms: {item.bathrooms}
              </Card.Description>
            </Card.Content>

            <Card.Content>
              {/* <strong style={{ color: 'rgba(0,0,0,.87)', fontWeight: '200', fontSize: 'large' }}><Icon name='euro' />
                {Intl.NumberFormat('de-DE', { style: 'decimal' }).format(item.price)}</strong> */}

              <span style={{ color: "#000", fontSize: "medium" }}>
                {item.priceDaily != null && item.priceDaily != 0 && (
                  <>
                    <Icon name="euro" size={"small"} style={{ margin: 0 }} />
                    {Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                      item.priceDaily
                    )}
                    /Day
                  </>
                )}
                &nbsp;&nbsp;
                <Icon name="euro" size={"small"} style={{ margin: 0 }} />
                {Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                  item.price
                )}
                {item.lettingType != 4 ? '' : ''} &nbsp;&nbsp;
                {item.priceYearly != null && item.priceYearly != 0 && (
                  <>
                    <Icon name="euro" size={"small"} style={{ margin: 0 }} />
                    {Intl.NumberFormat("de-DE", { style: "decimal" }).format(
                      item.priceYearly
                    )}
                    /Year
                  </>
                )}
              </span>
            </Card.Content>

            <div className="badges">
              <Button size="mini" color="black">
                {getLettingType(item.lettingType)}
              </Button>
              <Button
                size="mini"
                color={getAvailable(item?.availableDate) === "Available Now" ? "green" : (getAvailable(item?.availableDate) === "Available Soon"? "orange": "teal")}
              >
                {getAvailable(item.availableDate)}
              </Button>
            </div>
          </Card>
        </a>
      </div>
    );
  };

  const settings = {
    dots: true,
    lazyLoad: true,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile ? 1 : 4,
    slidesToScroll: isMobile ? 1 : 4,
  };

  return (
    <Container style={{ marginTop: 30 }}>
      <Grid>
        <Grid.Column computer="10">
          <h2>Properties Available Now</h2>
        </Grid.Column>
        <Grid.Column computer="6">
          <a href="/residentials">
            <h3 style={{ textAlign: "right" }}>View All Properties</h3>
          </a>
        </Grid.Column>
      </Grid>

      <Slider {...settings} style={{ marginTop: 20 }}>
        {data?.map((item) => renderItem(item))}
      </Slider>
    </Container>
  );
}

export default PropertiesSlider;
