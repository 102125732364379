import React from "react";
import { isMobile } from "react-device-detect";
import { Container, Grid, Image } from "semantic-ui-react";

function PropertyTypes() {

  return (
    <Container
      style={{ textAlign: "center", marginTop: "2vh", color: "#353a47" }}
    >
      <h1 className="pc-title">Most Popular Property Types</h1>

      <Grid>
        <Grid.Row columns={isMobile ? 2 : 7}>
          {items.map((item, i) => (
            <Grid.Column
              style={{ "margin-bottom": "8px", color: "rgb(53, 58, 71)" }}
              href={item.link}
            >
              <Image
                alt="easy-sale-malta"
                src={item.img}
                as="a"
                size="medium"
                rounded
                target="_blank"
              />
              {item.name}
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid>
    </Container>
  );
}

export default PropertyTypes;

const styles = {
  image: {
    objectFit: "cover",
    borderRadius: "5px",
  },
};

const items = [
  {
    name: "Luxury Villas For Sale in Malta",
    link: "https://easysale.com.mt/luxury-villas-for-sale-in-malta",
    img: "/img/home/luxury-villa.jpeg",
  },
  {
    name: "Townhouses For Sale in Malta",
    link: "https://easysale.com.mt/townhouses-for-sale-in-malta",
    img: "/img/home/townhouse.jpeg",
  },
  {
    name: "Penthouses For Sale in Malta",
    link: "https://easysale.com.mt/penthouses-for-sale-in-malta",
    img: "/img/home/penthouse.jpeg",
  },
  {
    name: "Maisonettes For Sale in Malta",
    link: "https://easysale.com.mt/maisonettes-for-sale-in-malta",
    img: "/img/home/maisonettes.jpeg",
  },
  {
    name: "Apartments For Sale in Malta",
    link: "https://easysale.com.mt/apartments-for-sale-in-malta",
    img: "/img/home/apartments.jpeg",
  },
  {
    name: "Farmhouses For Sale in Malta",
    link: "https://easysale.com.mt/farmhouses-for-sale-in-malta",
    img: "/img/home/farmhouse.jpeg",
  },
  {
    name: "Terraced Houses For Sale in Malta",
    link: "https://easysale.com.mt/terraced-houses-for-sale-in-malta",
    img: "/img/home/terraced.jpeg",
  },
  {
    name: "Houses of Character For Sale in Malta",
    link: "https://easysale.com.mt/houses-of-character-for-sale-in-malta",
    img: "/img/home/houses-of-character.jpeg",
  },
  {
    name: "Seafront Property For Sale in Malta",
    link: "https://easysale.com.mt/seafront-property-for-sale-in-malta",
    img: "/img/home/seafront.jpeg",
  },
  {
    name: "Bars For Sale in Malta",
    link: "https://easysale.com.mt/bars-for-sale-in-malta",
    img: "/img/home/bars.jpeg",
  },
  {
    name: "Offices For Sale in Malta",
    link: "https://easysale.com.mt/offices-for-sale-in-malta",
    img: "/img/home/office.jpeg",
  },
  {
    name: "Garage For Sale in Malta",
    link: "https://easysale.com.mt/garage-for-sale-in-malta",
    img: "/img/home/garrage.jpeg",
  },
  {
    name: "Hotels For Sale in Malta",
    link: "https://easysale.com.mt/hotels-for-sale-in-malta",
    img: "/img/home/hotels.jpeg",
  },
  {
    name: "Restaurant For Sale in Malta",
    link: "https://easysale.com.mt/restaurant-for-sale-in-malta",
    img: "/img/home/restaurants.jpeg",
  },
];
