import React, { Suspense } from "react";
import { BrowserView, MobileView } from "react-device-detect";

import Slider from "./slider";
import PopularCities from "./PopularCities";
import PropertiesSlider from './PropertiesSlider'

import "./styles.scss";
import "../../components/Footer/footer.scss";
import SliderForm from "./SliderForm";

import api from "../../api";
import { Helmet } from "react-helmet";
import { setRefId } from "../../helpers/cookie";

import * as images from "./MobileSliderPhoto";

import { useHistory } from "react-router-dom";
import LocationGroups from "./LocationGroups";
import PropertyTypes from "./PropertyTypes";

// const PropertiesSlider = React.lazy(() => import("./PropertiesSlider"));

const Home = ({ }) => {
  const [data, setData] = React.useState([]);

  const history = useHistory();
  const locationPathName = window.location.pathname;

  if (locationPathName.includes("detail")) {
    debugger;
    let url = window.location.href;
    let ref = url.split("?ref=");

    if (ref.length > 1) {
      setRefId(ref[1]);
    }

    history.push({
      pathname: `${locationPathName}/1`,
    });
  }

  React.useEffect(() => {
    const script = document.createElement("script");
    script.async = true;

    script.type = "application/ld+json";

    const codeStr = `
    "@context": "https://schema.org",
    "@type": "RealEstateAgent",
    "name": "Easy Sale",
    "image": "https://www.easysale.com.mt/img/logo-orange01.png",
    "@id": "",
    "url": "https://www.easysale.com.mt/",
    "telephone": "99998330",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "420 Triq D'Argens",
      "addressLocality": "Gżira",
      "postalCode": "",
      "addressCountry": "MT"
    } ,
    "sameAs": [
      "https://www.facebook.com/easyrentmt/",
      "https://www.instagram.com/easyrent.malta/",
      "https://www.youtube.com/channel/UCjo81yeSg4l4JJ3qkl79Hfw",
      "https://www.linkedin.com/company/easyrent-malta/"
    ] 
`;
    script.innerHTML = codeStr;

    document.body.appendChild(script);

    let url = window.location.href;
    let ref = url.split("?ref=");

    if (ref.length > 1) {
      setRefId(ref[1]);
    }
  }, []);

  React.useEffect(async () => {
    const res = await api.home.home();
    if (res?.result) {
      setData(res.result);
    }
  }, []);

  return (
    <div style={{ paddingBottom: "50px" }}>
      <Helmet>
        <title property="title">Easy Sale Malta - Expert Property Sales & Real Estate Services</title>
        <meta name="description" content="Discover your ideal property in Malta with Easy Sale, your trusted partner in real estate. Whether buying or selling, we offer personalized support and a professional team to help you navigate Malta’s property market with ease." />
        <meta name="keywords" content="apartment for sale, sale in malta, real estate in malta, accommodation in malta, residential apartments, commercial apartments, commercial property, property for sale" />
      </Helmet>

      <Suspense>
        <div className="home-slider">
          <BrowserView>
            <Suspense>
              <Slider />
            </Suspense>
          </BrowserView>
          <MobileView>
            {/* <div style={{ display: 'flex', justifyContent: 'center', padding: 20, backgroundImage: `url('/img/slider3.jpg')` }}>
            <SliderForm />
          </div> */}
            <div style={{ height: 500 }}>
              <img
                rel="preload"
                alt="img-mobile-slider"
                as="image"
                src={images.slider100}
                style={{ height: "500px" }}
              />
              <div
                style={{
                  position: "absolute",
                  top: "2%",
                  right: "10%",
                  zIndex: 1,
                }}
              >
                <SliderForm />
              </div>
            </div>
          </MobileView>
        </div>
      </Suspense>

      <Suspense fallback={<div>loading...</div>}>
        <PopularCities />
      </Suspense>

      <Suspense fallback={<div>loading...</div>}>
        <LocationGroups />
      </Suspense>

       <Suspense fallback={<div>loading...</div>}>
        <PropertyTypes />
      </Suspense> 

      <Suspense fallback={<div>loading...</div>}>
        <PropertiesSlider data={data} />
      </Suspense>
    </div>
  );
};

export default Home;
